import React, { PureComponent } from 'react'
import styled, { css } from 'styled-components'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import SettingsLayout from 'components/SettingsLayout/SettingsLayout'
import {
  Background,
  Button,
  CheckBoxCaption,
  Checkbox,
  Row,
  Column,
  ComponentWrapper,
  RequestDataBox,
  SectionTitle,
  Main2FAFlow,
  AuthConsumer,
  AuthContext,
  InputPassword,
  Text,
  Input,
  Select,
  FormField,
  FormError,
  FormInfo,
  Icon,
  iconsMap,
} from '@stokr/components-library'
import {
  AvatarContainer,
  AvatarExpandButton,
  AvatarImg,
  FlexCenterContainer,
  AvatarExpanded,
  AvatarModal,
  FileInputWrapper,
  FileInput,
  LinkInText,
  AvatarUploadContainer,
} from 'components/Settings/Settings.styles'
import countryList from 'static/country-list.json'
import avatarPlaceholder from 'static/images/avatar-placeholder.png'
import loader from 'static/images/process-waiting.gif'
import { Modal, ModalInner } from 'components/Modal/Modal'
import { getUnsubscription, setUnsubscription } from 'api/email-api'
import { withRouter } from 'utils/withRouter'
import Cookies from 'js-cookie'

const CTAContainer = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-left: 20px;
    width: 20px;
  }
`

const ErrorMessage = styled.p`
  color: #ee220d;
  font-size: 12px;
`
const SuccessMessage = styled.p`
  color: #79ca65;
  font-size: 12px;
`

const ChangePasswordContainer = styled.div`
  height: 0;
  opacity: 0;
  display: none;
  transition: all 0.6s;

  ${({ showChangePassword }) =>
    showChangePassword &&
    css`
      display: block;
      height: unset;
      opacity: 1;
    `}
`

const ShowChangePasswordButtonContainer = styled.div`
  ${({ showChangePassword }) =>
    showChangePassword &&
    css`
      display: none;
    `}
`
const emailSettingsList = [
  {
    id: 1,
    text: 'REMINDERS',
    caption:
      'Occasional reminders to complete setting up an unfinished profile',
    checked: true,
  },
  {
    id: 2,
    text: 'NEW OFFERING UPDATES',
    caption:
      'Promotional material related to new and upcoming offerings on STOKR',
    checked: false,
  },
  {
    id: 3,
    text: 'STOKR PRODUCT OR COMPANY UPDATES',
    caption: 'Information about STOKR, company newsletters, blog posts, etc.',
    checked: true,
  },
  {
    id: 4,
    text: 'INVITATIONS TO STOKR OR PARTNER EVENTS',
    caption: 'Invites to webinars, meetups or other events',
    checked: false,
  },
]

class UserAvatarComponent extends React.Component {
  state = {
    finalAvatar: undefined,
    tempAvatar: undefined,
  }

  componentDidMount() {
    const { avatar } = this.props

    this.checkUserPhoto(avatar)
  }

  componentDidUpdate(prevProps) {
    const { avatar, tempAvatar } = this.props

    if (!avatar && avatar !== prevProps.avatar) {
      this.setState({ finalAvatar: undefined })
    }

    if (avatar !== prevProps.avatar) {
      this.checkUserPhoto(avatar)
    }

    if (!tempAvatar && tempAvatar !== prevProps.tempAvatar) {
      this.setState({ tempAvatar: undefined })
    }

    if (tempAvatar && tempAvatar !== prevProps.tempAvatar) {
      this.setState({ tempAvatar, finalAvatar: undefined })
    }
  }

  checkUserPhoto = (avatar) => {
    try {
      const http = new XMLHttpRequest()
      http.open('HEAD', avatar, true)
      http.send()

      if (http.status !== 404) {
        this.setState({
          finalAvatar: avatar,
        })
      }
    } catch (error) {
      console.log('error: ', error)
    }
  }

  render() {
    const { finalAvatar, tempAvatar } = this.state

    return <AvatarImg src={tempAvatar || finalAvatar || avatarPlaceholder} />
  }
}

class AvatarBackgroundComponent extends React.Component {
  state = {
    finalAvatar: undefined,
    tempAvatar: undefined,
  }

  componentDidMount() {
    const { avatar } = this.props

    this.checkUserPhoto(avatar)
  }

  componentDidUpdate(prevProps) {
    const { avatar, tempAvatar } = this.props

    if (!avatar && avatar !== prevProps.avatar) {
      this.setState({ finalAvatar: undefined })
    }

    if (avatar !== prevProps.avatar) {
      this.checkUserPhoto(avatar)
    }

    if (!tempAvatar && tempAvatar !== prevProps.tempAvatar) {
      this.setState({ tempAvatar: undefined })
    }

    if (tempAvatar && tempAvatar !== prevProps.tempAvatar) {
      this.setState({ tempAvatar, finalAvatar: undefined })
    }
  }

  checkUserPhoto = (avatar) => {
    if (avatar && !avatar.includes('blob:')) {
      try {
        const http = new XMLHttpRequest()
        http.open('HEAD', avatar, false)
        http.send()

        if (http.status !== 404) {
          this.setState({
            finalAvatar: avatar,
          })
        }
      } catch (error) {
        console.log('error: ', error)
      }
    } else {
      this.setState({
        finalAvatar: avatar,
      })
    }
  }

  render() {
    const { finalAvatar, tempAvatar } = this.state

    return <Background src={tempAvatar || finalAvatar || avatarPlaceholder} />
  }
}

class SettingsProfileAndPrivacy extends PureComponent {
  state = {
    isAvatarModalOpen: false,
    newPictureName: '',
    shouldRenderPage: false,
    tempAvatar: undefined,
    isLoading: false,
    isLoadingPassword: false,
    error: undefined,
    errorPassword: undefined,
    showChangePassword: false,
    user: {},
    openEmailModal: false,
    emailSettingsData: null,
  }

  static contextType = AuthContext

  initialValues = {
    username: '',
    name: 'Sarah',
    lastname: 'Neumann',
    email: 'sarah@stokr.io',
    country: 'NL',
    nameShared: 'nobody',
    lastnameShared: 'community',
    countryShared: 'everyone',
  }

  validationSchema = {
    username: Yup.string(),
    name: Yup.string().required('First name is required'),
    lastname: Yup.string().required('Last name is required'),
    email: Yup.string().required('Email is required'),
    country: Yup.string().required('Country is required'),
  }

  validationSchemaPassword = Yup.object().shape({
    password: Yup.string().required('Oops, this can‘t be blank'),
    confirmPassword: Yup.string().required('Oops, this can‘t be blank'),
  })

  componentDidUpdate(prevProps) {
    const { avatar } = this.props
    if (avatar !== prevProps.avatar) {
      this.checkUserPhoto(avatar)
    }
  }

  async componentDidMount() {
    const data = await getUnsubscription()
    this.setState({ emailSettingsData: data.groups })
  }

  toggleAvatarModal = (isAvatarModalOpen) => {
    this.setState({
      isAvatarModalOpen,
    })
  }

  onUserDetailsSubmit = (e) => {
    console.log(e)
    // send request to api width image data
  }

  onPhotoChange = (e) => {
    const file = e.target.files[0]

    this.setState({
      newPictureName: file ? file.name : '',
      tempAvatar: URL.createObjectURL(file),
    })
  }

  clearFileInput = (e) => {
    this.fileInput.value = ''

    this.setState({
      newPictureName: '',
      tempAvatar: undefined,
    })
  }

  onPasswordChange = async (values) => {
    this.setState({ isLoadingPassword: true, errorPassword: undefined })
    const { user, updatePassword } = this.context
    try {
      await updatePassword(user, values.password)

      window.location.reload()
    } catch (error) {
      console.log('🚀 ~ file: profile-and-privacy.js:326 ~ error:', error)
      if (
        error?.code === 'auth/requires-recent-login' ||
        error?.code === 'auth/unsupported-first-factor'
      ) {
        this.props.navigate('/oops', {
          state: {
            message:
              'The action you requested requires recent login. Please log in and try again.',
            tokenExpired: true,
            title: 'Log in again',
            ctaUrl: '/login',
            ctaLabel: 'Log in',
            redirectUrl: '/settings/profile-and-privacy',
          },
        })
      } else {
        this.setState({
          isLoadingPassword: false,
          errorPassword: 'Something went wrong',
        })
      }
    }
  }

  onCheckboxChange = (id) => {
    var newList = []

    this.state.emailSettingsData.forEach((element) => {
      if (element.id === id) {
        element.suppressed = !element.suppressed
      }
      newList.push(element)
    })

    this.setState({ emailSettingsData: newList })
  }
  handleEmailSettings = async () => {
    var data = { groups: this.state.emailSettingsData }

    try {
      var result = await setUnsubscription(data)
      if (result.success) {
        this.setState({ openEmailModal: false })
      }
    } catch (error) {
      console.log(error)
    }
  }

  render() {
    const {
      isAvatarModalOpen,
      newPictureName,
      tempAvatar,
      isLoading,
      isLoadingPassword,
      error,
      errorPassword,
      showChangePassword,
      openEmailModal,
      emailSettingsData,
      success,
    } = this.state

    //didn't put in state because I want it to be reset on any page change
    const twoFAModalOpen = localStorage.getItem('2FA_MODAL_OPEN')
    if (twoFAModalOpen) {
      localStorage.removeItem('2FA_MODAL_OPEN')
    }

    return (
      <>
        {
          <SettingsLayout title="Settings" activeTab="profile-and-privacy">
            <ComponentWrapper>
              <Row>
                <Column part={6}>
                  <ComponentWrapper noPaddingHorizontal>
                    <SectionTitle>Settings</SectionTitle>
                  </ComponentWrapper>
                  <ComponentWrapper noPadding>
                    <Text>
                      <h3>Profile & Privacy</h3>
                      <p>
                        Your username and profile picture will be displayed
                        publicly with your posts in the community
                      </p>
                    </Text>
                  </ComponentWrapper>
                </Column>
                <Column part={10} flexEnd>
                  <AuthConsumer>
                    {({ avatar, deletePhoto }) => (
                      <AvatarUploadContainer>
                        <AvatarContainer>
                          <AvatarExpandButton
                            onClick={() => this.toggleAvatarModal(true)}
                          >
                            <Icon icon={iconsMap.expand} />
                          </AvatarExpandButton>
                          <UserAvatarComponent
                            tempAvatar={tempAvatar}
                            avatar={avatar}
                          />
                        </AvatarContainer>
                        <FileInputWrapper center minWidth="188px">
                          {newPictureName || 'UPLOAD PICTURE'}
                          <FileInput
                            ref={(el) => {
                              this.fileInput = el
                            }}
                            name="avatar-photo"
                            accept="image/png,image/jpeg"
                            onChange={this.onPhotoChange}
                          />
                        </FileInputWrapper>
                      </AvatarUploadContainer>
                    )}
                  </AuthConsumer>
                </Column>
              </Row>
              <AuthConsumer>
                {({ user, uploadPhoto, updateUser }) => {
                  if (Object.keys(user).length === 0) {
                    return false
                  }
                  return (
                    <Formik
                      initialValues={{
                        username: user.username,
                        name: user.name,
                        lastname: user.lastname,
                        email: user.email,
                        country: user.country,
                        taxId: user.taxId,
                        fullName: user.fullName,
                      }}
                      onSubmit={async (data) => {
                        this.setState({ isLoading: true, error: undefined })

                        try {
                          // Get changed fields by comparing with user object values
                          const changedFields = Object.keys(data).reduce(
                            (acc, key) => {
                              if (data[key] !== user[key]) {
                                acc[key] = data[key]
                              }
                              return acc
                            },
                            {},
                          )

                          // Only proceed if there are changes or a new photo
                          if (
                            Object.keys(changedFields).length > 0 ||
                            this.fileInput.files[0]
                          ) {
                            if (this.fileInput.files[0]) {
                              await uploadPhoto(this.fileInput.files[0])
                              this.clearFileInput()
                            }

                            if (Object.keys(changedFields).length > 0) {
                              await updateUser(changedFields)
                            }

                            this.setState({
                              isLoading: false,
                              success: 'Profile updated successfully!',
                            })
                            setTimeout(() => {
                              this.setState({
                                success: undefined,
                              })
                            }, 3500)
                          } else {
                            this.setState({
                              isLoading: false,
                              error: 'No changes to save',
                            })
                          }
                        } catch (error) {
                          this.setState({
                            isLoading: false,
                            error: 'Something went wrong.',
                          })
                          console.log(error)
                        }
                      }}
                    >
                      {({
                        errors,
                        touched,
                        setFieldValue,
                        setFieldTouched,
                      }) => {
                        const handleChangeSelect = (field) => {
                          setFieldValue(field.name, field.value, false)
                          setFieldTouched(field.name)
                        }

                        const handleBlurSelect = (field) => {
                          setFieldTouched(field.name)
                        }

                        return (
                          <Form>
                            <Row>
                              <Column part={6}>
                                <ComponentWrapper
                                  noPaddingHorizontal
                                  noPaddingBottom
                                  style={{
                                    opacity: '0.6',
                                    pointerEvents: 'none',
                                  }}
                                >
                                  <Field name="email">
                                    {({ field }) => (
                                      <FormField>
                                        <Input
                                          label="Email"
                                          id={field.name}
                                          error={!!errors[field.name]}
                                          touched={!!touched[field.name]}
                                          {...field}
                                          readOnly
                                          disabled
                                        />
                                        <FormError
                                          show={
                                            touched[field.name] &&
                                            errors[field.name]
                                          }
                                        >
                                          {errors[field.name]}
                                        </FormError>
                                      </FormField>
                                    )}
                                  </Field>
                                </ComponentWrapper>
                              </Column>
                              <Column part={1} />
                            </Row>
                            <Row>
                              <Column part={6}>
                                <ComponentWrapper
                                  noPaddingHorizontal
                                  noPaddingBottom
                                >
                                  <Field name="username">
                                    {({ field }) => (
                                      <FormField>
                                        <Input
                                          label="USERNAME"
                                          id={field.name}
                                          error={!!errors[field.name]}
                                          touched={!!touched[field.name]}
                                          {...field}
                                        />
                                        <FormError
                                          show={
                                            touched[field.name] &&
                                            errors[field.name]
                                          }
                                        >
                                          {errors[field.name]}
                                        </FormError>
                                        <FormInfo marginTop>
                                          Choose a kickass username. Get
                                          creative ;)
                                        </FormInfo>
                                      </FormField>
                                    )}
                                  </Field>
                                </ComponentWrapper>
                              </Column>
                            </Row>
                            {user.fullName && (
                              <Row>
                                <Column part={6}>
                                  <ComponentWrapper
                                    noPaddingHorizontal
                                    noPaddingBottom
                                  >
                                    <Field name="fullName">
                                      {({ field }) => (
                                        <FormField>
                                          <Input
                                            label="Name"
                                            id={field.name}
                                            error={!!errors[field.name]}
                                            touched={!!touched[field.name]}
                                            {...field}
                                            readOnly
                                            disabled
                                          />
                                          <FormError
                                            show={
                                              touched[field.name] &&
                                              errors[field.name]
                                            }
                                          >
                                            {errors[field.name]}
                                          </FormError>
                                        </FormField>
                                      )}
                                    </Field>
                                  </ComponentWrapper>
                                </Column>
                                <Column part={1} />
                              </Row>
                            )}

                            <Row>
                              <Column part={6}>
                                <ComponentWrapper
                                  noPaddingHorizontal
                                  noPaddingBottom
                                >
                                  <Field name="country">
                                    {({ field }) => (
                                      <FormField>
                                        {user.user_type !==
                                          'investor_entity' && (
                                          <>
                                            <Select
                                              options={countryList.map(
                                                (country) => ({
                                                  key: country.Code,
                                                  value: country.Name,
                                                  label: country.Name,
                                                }),
                                              )}
                                              label="Country of residence"
                                              placeholder="Select country"
                                              error={errors[field.name]}
                                              touched={touched[field.name]}
                                              id={field.name}
                                              name={field.name}
                                              value={field.value}
                                              onChange={handleChangeSelect}
                                              onBlur={handleBlurSelect}
                                              disabled={
                                                user.kyc_status &&
                                                user.kyc_status !== 'Draft'
                                              }
                                            />

                                            <FormError
                                              show={
                                                touched[field.name] &&
                                                errors[field.name]
                                              }
                                            >
                                              {errors[field.name]}
                                            </FormError>
                                            {user.kyc_status &&
                                              user.kyc_status !== 'Draft' &&
                                              !user.taxId && (
                                                <FormInfo marginTop>
                                                  You cannot change your country
                                                  of residence after having been
                                                  verified. Please go to the
                                                  Data Management below.
                                                </FormInfo>
                                              )}
                                          </>
                                        )}
                                      </FormField>
                                    )}
                                  </Field>
                                </ComponentWrapper>
                              </Column>
                              <Column part={1} />
                            </Row>
                            {user.taxId && (
                              <Row>
                                <Column part={6}>
                                  <ComponentWrapper
                                    noPaddingHorizontal
                                    noPaddingBottom
                                    style={
                                      {
                                        // opacity: '0.6',
                                        // pointerEvents: 'none',
                                      }
                                    }
                                  >
                                    <Field name="taxId">
                                      {({ field }) => (
                                        <FormField>
                                          <Input
                                            label="Tax Id"
                                            id={field.name}
                                            error={!!errors[field.name]}
                                            touched={!!touched[field.name]}
                                            {...field}
                                            readOnly
                                            disabled
                                          />

                                          <FormError
                                            show={
                                              touched[field.name] &&
                                              errors[field.name]
                                            }
                                          >
                                            {errors[field.name]}
                                          </FormError>

                                          {user.kyc_status &&
                                            user.kyc_status !== 'Draft' && (
                                              <FormInfo marginTop>
                                                You cannot change your country
                                                of residence or tax ID after
                                                having been verified. Please go
                                                to the Data Management below.
                                              </FormInfo>
                                            )}
                                        </FormField>
                                      )}
                                    </Field>
                                  </ComponentWrapper>
                                </Column>
                                <Column part={1} />
                              </Row>
                            )}
                            <Row>
                              <Column>
                                <ComponentWrapper noPaddingHorizontal>
                                  <CTAContainer>
                                    <Button type="submit" minWidth="150px">
                                      Save Changes
                                    </Button>
                                    {isLoading && <img src={loader} alt="" />}
                                  </CTAContainer>
                                </ComponentWrapper>
                                {error && <ErrorMessage>{error}</ErrorMessage>}
                                {success && (
                                  <SuccessMessage>{success}</SuccessMessage>
                                )}
                              </Column>
                            </Row>
                          </Form>
                        )
                      }}
                    </Formik>
                  )
                }}
              </AuthConsumer>

              {/* 2fa flow */}
              <Main2FAFlow
                onLoginAgainClick={() => {
                  localStorage.setItem('2FA_MODAL_OPEN', 'true')
                  Cookies.set(
                    'STOKR_REDIRECT_URL',
                    '/settings/profile-and-privacy',
                  )
                  this.context?.logoutUser()
                  this.props.navigate('/login')
                }}
                // onRequiresRecentLoginError={() => {
                //   this.props.navigate('/oops', {
                //     state: {
                //       title: 'LOG IN AGAIN',
                //       message:
                //         'To enable your log in two factor authentication please log in again.',
                //       tokenExpired: true,
                //       ctaUrl: '/login',
                //       ctaLabel: 'Log in again',
                //       redirectUrl: '/settings/profile-and-privacy',
                //     },
                //   })
                // }}
                open2faflow={twoFAModalOpen}
              />

              {/* Password change */}
              <Row>
                <Column part={6}>
                  <FlexCenterContainer noPaddingHorizontal>
                    <Text>
                      <h5>Reset your password</h5>
                    </Text>
                  </FlexCenterContainer>
                  <Text>
                    <p>Choose a strong password, so you're safe and sound</p>
                  </Text>
                </Column>
              </Row>

              <ChangePasswordContainer showChangePassword={showChangePassword}>
                <Formik
                  initialValues={{
                    password: '',
                    confirmPassword: '',
                  }}
                  validationSchema={this.validationSchemaPassword}
                  onSubmit={(values) => {
                    this.onPasswordChange(values)
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    setFieldValue,
                    setFieldTouched,
                  }) => {
                    const onChangeWithTouch = (e) => {
                      const field = e.target
                      setFieldValue(field.name, field.value, false)
                      setFieldTouched(field.name)
                    }

                    const submitDisabled =
                      values.password !== values.confirmPassword ||
                      values.password.length <= 5

                    return (
                      <Form>
                        <Row>
                          <Column part={6}>
                            <ComponentWrapper
                              noPaddingHorizontal
                              noPaddingBottom
                            >
                              <FormField>
                                <InputPassword
                                  id="reset-password"
                                  name="password"
                                  type="password"
                                  label="Password"
                                  value={values.password}
                                  onChange={onChangeWithTouch}
                                  onBlur={handleBlur}
                                  error={!!errors.password}
                                  touched={!!touched.password}
                                  info="For a stronger password, try a mix of lowercase, capitals, numbers and special characters."
                                  // showStrength
                                />
                                <FormError
                                  show={errors.password && touched.password}
                                >
                                  {errors.password}
                                </FormError>
                                <FormError
                                  show={
                                    !errors.password &&
                                    touched.password &&
                                    (values.password.length <= 5 ||
                                      values.password !==
                                        values.confirmPassword)
                                  }
                                >
                                  The passwords must match and have at least 6
                                  characters
                                </FormError>
                              </FormField>
                            </ComponentWrapper>
                          </Column>
                        </Row>

                        <Row>
                          <Column part={6}>
                            <ComponentWrapper
                              noPaddingHorizontal
                              noPaddingBottom
                            >
                              <FormField>
                                <InputPassword
                                  id="confirm-reset-password"
                                  name="confirmPassword"
                                  type="password"
                                  label="Confirm password"
                                  value={values.confirmPassword}
                                  onChange={onChangeWithTouch}
                                  onBlur={handleBlur}
                                  error={!!errors.confirmPassword}
                                  touched={!!touched.confirmPassword}
                                />
                                <FormError
                                  show={
                                    errors.confirmPassword &&
                                    touched.confirmPassword
                                  }
                                >
                                  {errors.confirmPassword}
                                </FormError>
                              </FormField>
                            </ComponentWrapper>
                          </Column>
                        </Row>

                        <Row>
                          <Column>
                            <ComponentWrapper noPaddingHorizontal>
                              <CTAContainer>
                                <Button
                                  type="submit"
                                  minWidth="150px"
                                  disabled={submitDisabled}
                                >
                                  Reset Password
                                </Button>
                                {isLoadingPassword && (
                                  <img src={loader} alt="" />
                                )}
                              </CTAContainer>
                            </ComponentWrapper>
                            {errorPassword && (
                              <ErrorMessage>{errorPassword}</ErrorMessage>
                            )}
                            <ComponentWrapper />
                          </Column>
                        </Row>
                      </Form>
                    )
                  }}
                </Formik>
              </ChangePasswordContainer>

              <ShowChangePasswordButtonContainer
                showChangePassword={showChangePassword}
              >
                <Row>
                  <Column>
                    <ComponentWrapper noPaddingHorizontal>
                      <CTAContainer>
                        <Button
                          onClick={() =>
                            this.setState({ showChangePassword: true })
                          }
                        >
                          Reset password
                        </Button>
                      </CTAContainer>
                    </ComponentWrapper>
                    <ComponentWrapper />
                  </Column>
                </Row>
              </ShowChangePasswordButtonContainer>

              <Row>
                <Column part={9}>
                  <FlexCenterContainer noPaddingHorizontal>
                    <Text>
                      <h5>data management</h5>
                    </Text>
                  </FlexCenterContainer>
                  <Text>
                    <p>
                      {`We value your privacy. If you want to get informed about all the in's and out's you can read our `}
                      <LinkInText
                        href="https://stokr.io/terms"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms of Use
                      </LinkInText>
                      {', '}
                      <LinkInText
                        href="https://stokr.io/privacy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Privacy Terms
                      </LinkInText>
                      {', and '}
                      <LinkInText
                        href="https://stokr.io/legal"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        General Terms
                      </LinkInText>
                    </p>
                  </Text>
                </Column>
                <Column>
                  <ComponentWrapper noPaddingHorizontal>
                    <RequestDataBox
                      title="Change Email Settings"
                      subtitle="Modify the type of emails you receive from us."
                      onClick={() => this.setState({ openEmailModal: true })}
                    />
                    <a
                      href={
                        'mailto:compliance@stokr.io?subject=STOKR Interface - Request Personal Data'
                      }
                    >
                      <RequestDataBox
                        title="Request Data"
                        subtitle="Request a copy of your personal data from STOKR."
                      />
                    </a>
                    <a
                      href={
                        'mailto:compliance@stokr.io?subject=STOKR Interface - Request Data Deletion'
                      }
                    >
                      <RequestDataBox
                        title="Request Deletion"
                        subtitle="Request deletion of some or all of your data on STOKR."
                      />
                    </a>
                    <a
                      href={
                        'mailto:compliance@stokr.io?subject=STOKR Interface - Request Data Correction'
                      }
                    >
                      <RequestDataBox
                        title="Request Correction"
                        subtitle="Request to correct or complete your data on STOKR."
                      />
                    </a>
                  </ComponentWrapper>
                </Column>
              </Row>
            </ComponentWrapper>
            <AvatarModal
              isOpen={isAvatarModalOpen}
              onClose={() => this.toggleAvatarModal(false)}
            >
              <AvatarExpanded>
                <AuthConsumer>
                  {({ avatar }) => (
                    <AvatarBackgroundComponent avatar={tempAvatar || avatar} />
                  )}
                </AuthConsumer>
              </AvatarExpanded>
            </AvatarModal>
            <Modal
              thin
              isOpen={openEmailModal}
              onClose={() => this.setState({ openEmailModal: false })}
            >
              <ModalInner>
                <ComponentWrapper noPaddingHorizontal noPaddingBottom>
                  <Text>
                    <h5>Change Email settings</h5>
                    <p>Modify the type of emails you receive from us</p>
                  </Text>
                </ComponentWrapper>
                {emailSettingsData &&
                  emailSettingsData.map((setting) => (
                    <ComponentWrapper
                      noPaddingHorizontal
                      noPaddingBottom
                      key={setting.id}
                    >
                      <Checkbox
                        text={
                          <>
                            {setting.name.toUpperCase()}
                            <CheckBoxCaption>
                              {setting.description}
                            </CheckBoxCaption>
                          </>
                        }
                        checked={!setting.suppressed}
                        id={setting.id.toString()}
                        textStyle={{ fontWeight: 600 }}
                        onChange={() => this.onCheckboxChange(setting.id)}
                      />
                    </ComponentWrapper>
                  ))}
                <ComponentWrapper
                  noPaddingHorizontal
                  fluidcenter
                  noPaddingBottom
                >
                  <Button onClick={this.handleEmailSettings}>Save</Button>
                </ComponentWrapper>
              </ModalInner>
            </Modal>
          </SettingsLayout>
        }
      </>
    )
  }
}

export default withRouter(SettingsProfileAndPrivacy)
